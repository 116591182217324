import PropTypes from 'prop-types';
import ReactComponent from 'components/react-component';

const FilteredListItem = {};

FilteredListItem.propTypes = {
  category: PropTypes.string.isRequired,
  component: PropTypes.shape(ReactComponent.propTypes).isRequired,
};

export default FilteredListItem;

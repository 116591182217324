import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';

const FactBlock = ({ textHtml }) => {
  return (
    textHtml && (
      <div className="fact-block block">
        <RichText
          text={textHtml}
          className="fact-block__content rich-text_in-fact-block"
        />
      </div>
    )
  );
};

FactBlock.propTypes = {
  textHtml: PropTypes.string,
};

export default FactBlock;

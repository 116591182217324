import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import cn from 'classnames';
import BreadcrumbsSchema from './breadcrumbsSchema';

const Breadcrumbs = ({
  links,
  className,
  lang = { ariaLabel: 'Breadcrumb' },
}) => {
  return (
    <>
      <BreadcrumbsSchema links={links} />
      <nav className={cn('breadcrumbs', className)} aria-label={lang.ariaLabel}>
        {links && (
          <ol className="breadcrumbs__items">
            {links.map((link, i) => {
              const isLastLink = i === links.length - 1;
              const ariaCurrent = isLastLink ? 'page' : null;

              return (
                <li key={i} className="breadcrumbs__item">
                  <Link
                    {...link}
                    className={cn('breadcrumbs__link', {
                      breadcrumbs__link_current: isLastLink,
                    })}
                    ariaCurrent={ariaCurrent}
                    tabindex={0}
                  />
                  {!isLastLink && (
                    <span className="breadcrumbs__separator" aria-hidden="true">
                      /
                    </span>
                  )}
                </li>
              );
            })}
          </ol>
        )}
      </nav>
    </>
  );
};

Breadcrumbs.propTypes = {
  lang: PropTypes.shape({
    ariaLabel: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  className: PropTypes.string,
};

Breadcrumbs.viewModelMeta = {
  className: 'ignore',
};

export default Breadcrumbs;

import React from 'react';
import Link from 'components/link';
import Icon from 'components/icon';
import MenuLink from 'components/models/menu-link';
import useToggle from 'hooks/use-toggle';
import useDependentToggle from 'hooks/use-dependent-toggle';
import PropTypes from 'prop-types';
import cn from 'classnames';
import messageBus from 'js/message-bus';

const MainMenu = ({ links, lang, className }) => {
  const { isActive, toggle, deactivate } = useToggle(false);

  useDependentToggle({
    isActive,
    messageTypeToSend: messageBus.messageTypes.mobileMenuOpened,
    messageTypeToSubscribe:
      messageBus.messageTypes.mobileLanguageSelectorOpened,
    deactivate,
  });

  return links && links.length > 0 ? (
    <nav
      className={cn('main-menu', {
        [className]: className,
      })}
    >
      <button
        onClick={toggle}
        className="main-menu__toggle-button"
        aria-label={isActive ? lang.closeMenuLabel : lang.openMenuLabel}
      >
        <Icon
          name={isActive ? 'close' : 'menu'}
          className="header-menu__toggle-button-icon"
        />
        <span className="main-menu__toggle-button-text">{lang.menuLabel}</span>
      </button>
      <ul
        className={cn('main-menu__list', {
          'main-menu__list_active': isActive,
        })}
      >
        {links.map((link, index) => (
          <li key={index} className="main-menu__item">
            <Link
              {...link}
              className={cn('main-menu__link', {
                'main-menu__link_active': link.isActive,
              })}
            />
          </li>
        ))}
      </ul>
    </nav>
  ) : null;
};

MainMenu.viewModelMeta = {
  className: 'ignore',
};

MainMenu.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)).isRequired,
  lang: PropTypes.shape({
    menuLabel: PropTypes.string.isRequired,
    openMenuLabel: PropTypes.string.isRequired,
    closeMenuLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainMenu;

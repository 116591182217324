import PubSub from 'pubsub-js';

const messageTypes = {
  categoryFilterUpdated: 'category-filter-updated',
  currentCategoryFilterRequest: 'current-category-filter-request',
  mobileMenuOpened: 'mobile-menu-opened',
  mobileLanguageSelectorOpened: 'mobile-language-selector-opened',
};

function onTopic(topic, func) {
  if (typeof func !== 'function') {
    return;
  }
  return PubSub.subscribe(topic, (topic, messageData) => {
    func(messageData);
  });
}

function send(topic, messageData) {
  PubSub.publish(topic, messageData);
}

const onMessage = (topic, func) => onTopic(topic, func);
const sendMessage = (topic, messageData) => send(topic, messageData);

export default {
  onMessage,
  sendMessage,
  messageTypes,
};

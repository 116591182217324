import React from 'react';
import PropTypes from 'prop-types';
import ImageBlock from 'components/image-block';
import RichText from 'components/rich-text';
import cn from 'classnames';

const position = {
  left: 'left',
  right: 'right',
};

const verticalAlignment = {
  top: 'top',
  center: 'center',
  bottom: 'bottom',
};

const ImageWithTextBlock = ({
  textHtml,
  image,
  imagePosition,
  imageVAlignment,
}) => {
  return (
    <div
      className={cn('image-with-text-block block', {
        [`image-with-text-block_image-position_${imagePosition}`]:
          imagePosition,
        [`image-with-text-block_image-v-alignment_${imageVAlignment}`]:
          imagePosition,
      })}
    >
      <div className="image-with-text-block__image-container">
        {image && (
          <ImageBlock
            {...image}
            className="image-with-text-block__image"
            imageSizeClassName="image_size_image-with-text-block"
          />
        )}
      </div>
      <div className="image-with-text-block__text-container">
        <RichText text={textHtml} />
      </div>
    </div>
  );
};

ImageWithTextBlock.propTypes = {
  textHtml: PropTypes.string,
  image: PropTypes.exact(ImageBlock.propTypes),
  imagePosition: PropTypes.oneOf(Object.values(position)).isRequired,
  imageVAlignment: PropTypes.oneOf(Object.values(verticalAlignment)).isRequired,
};

export default ImageWithTextBlock;

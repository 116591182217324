import React from 'react';
import Link from 'components/link';
import PoorText from 'components/poor-text/poor-text';
import PropTypes from 'prop-types';

const HighlightedIssue = ({ title, introHtml, url }) => {
  return (
    <div className="highlighted-issue">
      <div className="highlighted-issue__container">
        {title && (
          <h2 className="highlighted-issue__heading">
            {url ? (
              <Link
                text={title}
                url={url}
                className="highlighted-issue__link"
              />
            ) : (
              title
            )}
          </h2>
        )}
        {introHtml && (
          <PoorText
            text={introHtml}
            className="poor-text_in-highlighted-issue"
          />
        )}
      </div>
    </div>
  );
};

HighlightedIssue.propTypes = {
  title: PropTypes.string,
  introHtml: PropTypes.string,
  url: PropTypes.string,
};

export default HighlightedIssue;

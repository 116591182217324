import { useEffect } from 'react';
import PropTypes from 'prop-types';

const PARENT_SELECTOR = 'page__root';
const EXTERNAL_LINK_CLASS_NAME = 'link-type_external';

const processLink = (link, lang) => {
  const isWebLink = link.protocol === 'http:' || link.protocol === 'https:';
  const isExternal = isWebLink && link.hostname !== window.location.hostname;
  const opensNewTab = link.target === '_blank';

  if (isExternal && opensNewTab) {
    link.classList.add(EXTERNAL_LINK_CLASS_NAME);
    link.setAttribute(
      'aria-label',
      `${lang.externalLinkLabel}, ${lang.opensNewTabLabel.toLowerCase()}`
    );
  } else if (isExternal) {
    link.classList.add(EXTERNAL_LINK_CLASS_NAME);
    link.setAttribute('aria-label', lang.externalLinkLabel);
  } else if (opensNewTab) {
    link.setAttribute('aria-label', lang.opensNewTabLabel);
  }
};

const LinkTypeProcessor = ({
  lang = {
    externalLinkLabel: 'External link',
    opensNewTabLabel: 'Opens a new tab',
  },
}) => {
  useEffect(() => {
    const parentElement = document.querySelector(`.${PARENT_SELECTOR}`);

    if (!parentElement) return;

    const links = document.querySelectorAll('a');
    links.forEach(link => processLink(link, lang));

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.tagName === 'A') {
              processLink(node, lang);
            } else if (node.querySelectorAll) {
              const newLinks = node.querySelectorAll('a');
              newLinks.forEach(link => processLink(link, lang));
            }
          });
        }
      });
    });

    observer.observe(parentElement, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [lang]);

  return null;
};

LinkTypeProcessor.propTypes = {
  lang: PropTypes.shape({
    externalLinkLabel: PropTypes.string.isRequired,
    opensNewTabLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default LinkTypeProcessor;

import PropTypes from 'prop-types';

const Language = {};

Language.propTypes = {
  displayName: PropTypes.string.isRequired,
  url: PropTypes.string,
  name: PropTypes.string,
  isCurrent: PropTypes.bool,
};

export default Language;

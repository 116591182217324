import PropTypes from 'prop-types';

const FilterTab = {};

FilterTab.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  category: PropTypes.string,
};

export default FilterTab;

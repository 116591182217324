import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import cn from 'classnames';

const ImageBlock = ({
  image,
  caption,
  photograph,
  className,
  imageSizeClassName,
  lang,
}) => {
  const imageClass = imageSizeClassName
    ? imageSizeClassName
    : 'image_size_image-block';
  return (
    <div className={cn('image-block block', className)}>
      <figure className="image-block__container">
        <Image className={imageClass} {...image} />
        {(caption || photograph) && (
          <figcaption className="image-block__text-container">
            {caption && <div className="image-block__caption">{caption}</div>}
            {photograph && (
              <div className="image-block__photographer">
                <span className="image-block__photographer-label">
                  {lang.photographLabel}
                </span>
                <span className="image-block__photographer-text">
                  {photograph}
                </span>
              </div>
            )}
          </figcaption>
        )}
      </figure>
    </div>
  );
};

ImageBlock.propTypes = {
  lang: PropTypes.shape({
    photographLabel: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.exact(Image.propTypes).isRequired,
  caption: PropTypes.string,
  className: PropTypes.string,
  imageSizeClassName: PropTypes.string,
  photograph: PropTypes.string,
};

ImageBlock.viewModelMeta = {
  className: 'ignore',
  imageSizeClassName: 'ignore',
};

export default ImageBlock;

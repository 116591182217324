import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/link-button';
import RichText from 'components/rich-text';

const NotFoundPage = ({ heading, errorHeading, textHtml, homePageButton }) => {
  return (
    <div className="not-found-page">
      <div className="not-found-page__image" role="presentation"></div>
      <div className="not-found-page__container">
        <div className="not-found-page__text-container">
          {errorHeading && (
            <h1 className="not-found-page__error-heading">{errorHeading}</h1>
          )}
          {heading && <h2 className="not-found-page__heading">{heading}</h2>}
          {textHtml && (
            <RichText
              text={textHtml}
              className="not-found-page__text rich-text_in-not-found-page"
            />
          )}
          {homePageButton && (
            <LinkButton
              {...homePageButton}
              className="not-found-page__button"
            />
          )}
        </div>
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  errorHeading: PropTypes.string,
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  homePageButton: PropTypes.exact(LinkButton.propTypes),
};

export default NotFoundPage;

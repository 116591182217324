import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Footnote from './footnote';
import { scrollToBlock } from 'js/scroll-to-utils';

const Footnotes = ({ heading, items }) => {
  const [activeFootnoteKey, setActiveFootnoteKey] = useState(null);

  useEffect(() => {
    const editModeRoot = document.querySelector('.on-page-editing');
    const footnoteReferences = document.querySelectorAll('.footnote-reference');
    const footnotes = document.querySelectorAll('.footnote');

    if (!editModeRoot && footnotes && footnotes.length > 0) {
      for (let i = 0; i < footnoteReferences.length; i++) {
        const footnoteReference = footnoteReferences[i];
        const footnoteKey = footnoteReference.innerText;
        const relatedFootnote = document.getElementById(footnoteKey);

        if (relatedFootnote) {
          const relatedFootnoteIndex = Array.prototype.indexOf.call(
            footnotes,
            relatedFootnote
          );

          if (relatedFootnoteIndex > -1) {
            footnoteReference.innerText = relatedFootnoteIndex + 1;
            footnoteReference.classList.add('footnote-reference_enabled');

            footnoteReference.addEventListener('click', () => {
              setActiveFootnoteKey(footnoteKey);

              scrollToBlock({
                el: relatedFootnote,
                isCentered: true,
              });
            });
          }
        }
      }
    }
  }, []);

  return (
    <div className="footnotes">
      <div className="footnotes__container">
        {heading && <h2 className="footnotes__heading">{heading}</h2>}
        {items.length > 0 && (
          <ol className="footnotes__list">
            {items.map((item, index) => (
              <li className="footnotes__item" key={index}>
                <Footnote
                  {...item}
                  isActive={activeFootnoteKey === item.footnoteKey}
                  index={index}
                />
              </li>
            ))}
          </ol>
        )}
      </div>
    </div>
  );
};

Footnotes.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(Footnote.propTypes)).isRequired,
};

export default Footnotes;

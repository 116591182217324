import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';
import LinkButton from 'components/link-button/link-button';
import cn from 'classnames';

const CtaImageBlock = ({ ctaButton, backgroundImage, isFullWidth = false }) => {
  return (
    <div className="cta-image-block block">
      <div className="cta-image-block__container">
        <div className="cta-image-block__button-container">
          <LinkButton {...ctaButton} className="cta-image-block__button" />
        </div>
        <Image
          {...backgroundImage}
          className={cn(
            'cta-image-block__image',
            isFullWidth
              ? 'image_size_cta-image-block-full-width'
              : 'image_size_cta-image-block'
          )}
        />
      </div>
    </div>
  );
};

CtaImageBlock.propTypes = {
  ctaButton: PropTypes.exact(LinkButton.propTypes).isRequired,
  backgroundImage: PropTypes.exact(Image.propTypes).isRequired,
  isFullWidth: PropTypes.bool, //TODO: set isFullWidth in parent block (topic group)
};

CtaImageBlock.viewModelMeta = {
  isFullWidth: 'ignore',
};

export default CtaImageBlock;

import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';
import Link from 'components/link/link';
import PoorText from 'components/poor-text/poor-text';
import cn from 'classnames';

const position = {
  left: 'left',
  right: 'right',
};

const FullWidthBannerBlock = ({
  heading,
  url,
  introHtml,
  backgroundImage,
  textPosition,
}) => {
  return (
    <div
      className={cn('full-width-banner-block block', {
        [`full-width-banner-block_text-position_${textPosition}`]: textPosition,
      })}
    >
      <div className="full-width-banner-block__container">
        {(heading || introHtml) && (
          <div className="full-width-banner-block__text-content">
            {heading && (
              <h2 className="full-width-banner-block__heading">
                {url ? (
                  <Link
                    text={heading}
                    url={url}
                    className="full-width-banner-block__link"
                  />
                ) : (
                  <>{heading}</>
                )}
              </h2>
            )}
            {introHtml && (
              <PoorText
                text={introHtml}
                className="full-width-banner-block__intro poor-text_in_full-width-banner-block"
              />
            )}
          </div>
        )}
      </div>
      <Image
        {...backgroundImage}
        className="full-width-banner-block__image image_size_full-width-banner-block"
      />
    </div>
  );
};

FullWidthBannerBlock.propTypes = {
  heading: PropTypes.string,
  url: PropTypes.string,
  introHtml: PropTypes.string,
  backgroundImage: PropTypes.exact(Image.propTypes),
  textPosition: PropTypes.oneOf(Object.values(position)).isRequired,
};

export default FullWidthBannerBlock;

/* eslint-disable view-models/no-unused-meta */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React from 'react';
import LinkBase from 'components/models/link-base';
import PropTypes from 'prop-types';
import cn from 'classnames';

const LinkButton = ({
  className,
  openInNewTab = false,
  url,
  text,
  tabindex,
}) => {
  return url ? (
    <a
      href={url}
      className={cn('link-button', {
        [className]: className,
      })}
      target={openInNewTab ? '_blank' : null}
      rel={openInNewTab ? 'nofollow noopener' : null}
      tabIndex={tabindex}
    >
      {text}
    </a>
  ) : (
    <span className="link-button__text">{text}</span>
  );
};

LinkButton.viewModelMeta = {
  className: 'ignore',
  tabindex: 'ignore',
};

LinkButton.propTypes = Object.assign({}, LinkBase.propTypes, {
  className: PropTypes.string,
  tabindex: PropTypes.number,
});

export default LinkButton;

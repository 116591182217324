import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CircleIcon from 'components/circle-icon';
import Tab from './tab';

const tabContentClassName = 'tab-content';
const tabContentActiveClassName = 'tab-content_active';

const TabsPanel = ({ tabs, activeTabIndex = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(activeTabIndex);
  const [hashChecked, setHashChecked] = useState(false);

  const displayActiveTabContent = contentId => {
    const allTabsContent = document.querySelectorAll(`.${tabContentClassName}`);
    const activeTabId = `#${contentId}`;
    const activeTabContent = document.querySelector(activeTabId);
    const activeTabIndex = tabs.findIndex(tab => tab.contentId === contentId);

    if (activeTabIndex > -1) {
      setActiveIndex(activeTabIndex);
    }

    for (let i = 0; i < allTabsContent.length; i++) {
      allTabsContent[i].classList.remove(tabContentActiveClassName);
    }

    if (activeTabContent) {
      activeTabContent.classList.add(tabContentActiveClassName);
    }
  };

  const onTabItemClick = (e, contentId) => {
    e.preventDefault();
    window.location.hash = contentId;
  };

  const displayTabContentBasedOnHash = useCallback(() => {
    displayActiveTabContent(window.location.hash.substring(1));
  });

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      displayActiveTabContent(hash.substring(1));
    } else {
      const activeTab = tabs[activeIndex];
      if (activeTab && activeTab.contentId) {
        displayActiveTabContent(activeTab.contentId);
      }
    }

    setHashChecked(true);

    window.addEventListener('hashchange', displayTabContentBasedOnHash, false);

    return () =>
      window.removeEventListener(
        'hashchange',
        displayTabContentBasedOnHash,
        false
      );
  }, []);

  return !tabs || tabs.length <= 0 ? null : (
    <div className="tabs-panel">
      <ul className="tabs-panel__list">
        {tabs.map((item, index) => {
          const isActive = hashChecked && index === activeIndex;

          return (
            <li key={index} className="tabs-panel__item">
              <a
                href={`#${item.contentId}`}
                className={cn('tabs-panel__link', {
                  'tabs-panel__link_active': isActive,
                })}
                onClick={e => onTabItemClick(e, item.contentId)}
              >
                {item.icon && (
                  <CircleIcon
                    name={item.icon}
                    isActive={isActive}
                    className="tabs-panel__icon"
                  />
                )}
                <span className="tabs-panel__text">{item.name}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TabsPanel.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.exact(Tab.propTypes)).isRequired,
  activeTabIndex: PropTypes.number,
};

export default TabsPanel;

import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import MainMenu from 'components/main-menu';
import LanguageSelector from 'components/language-selector';

const Header = ({ mainLogo, secondaryLogo, mainMenu, languageSelector }) => {
  return (
    <div className="header">
      <div className="header__container">
        {mainLogo && (
          <div className="header__logo-holder header__logo-holder-main">
            <Logo
              {...mainLogo}
              className="header__logo-main image_size_header-main-logo"
            />
          </div>
        )}
        {mainMenu && <MainMenu {...mainMenu} className="header__main-menu" />}
        {languageSelector && (
          <LanguageSelector
            {...languageSelector}
            className="header__language-selector"
          />
        )}
        {secondaryLogo && (
          <div className="header__logo-holder header__logo-holder-secondary">
            <Logo
              {...secondaryLogo}
              className="header__logo-secondary image_size_header-secondary-logo"
            />
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  mainLogo: PropTypes.exact(Logo.propTypes),
  secondaryLogo: PropTypes.exact(Logo.propTypes),
  mainMenu: PropTypes.exact(MainMenu.propTypes),
  languageSelector: PropTypes.exact(LanguageSelector.propTypes),
};

export default Header;

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable view-models/no-unused-meta */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import LinkBase from 'components/models/link-base';
import cn from 'classnames';

const Link = ({
  className,
  openInNewTab = false,
  url,
  text,
  tabindex,
  ariaCurrent,
  onFocus = () => {},
  onBlur = () => {},
}) => (
  <a
    href={url}
    className={cn('link', {
      [className]: className,
    })}
    target={openInNewTab ? '_blank' : null}
    rel={openInNewTab ? 'nofollow noopener noreferrer' : null}
    tabIndex={tabindex}
    aria-current={ariaCurrent}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    {text}
  </a>
);

Link.viewModelMeta = {
  className: 'ignore',
  tabindex: 'ignore',
  onFocus: 'ignore',
  onBlur: 'ignore',
  ariaCurrent: 'ignore',
};

Link.propTypes = Object.assign({}, LinkBase.propTypes, {
  className: PropTypes.string,
  tabindex: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  ariaCurrent: PropTypes.string,
});

export default Link;

import React from 'react';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CircleIcon = ({ name, isActive, className }) => {
  return (
    <div
      className={cn('circle-icon', {
        'circle-icon_active': isActive,
        [className]: className,
      })}
    >
      <Icon name={name} className="circle-icon__image" />
    </div>
  );
};

CircleIcon.propTypes = {
  name: PropTypes.string,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

CircleIcon.viewModelMeta = 'ignore';

export default CircleIcon;

import React from 'react';
import Link from 'components/link';
import Icon from 'components/icon';
import PropTypes from 'prop-types';

const QaGroupBlock = ({ heading, links }) => {
  return (
    <div className="qa-group-block block">
      {heading && <h2 className="qa-group-block__heading">{heading}</h2>}
      <ul className="qa-group-block__list">
        {links.map((link, index) => (
          <li className="qa-group-block__item" key={index}>
            <Link {...link} className="qa-group-block__link" />
            <Icon name="arrow-right" className="qa-group-block__arrow" />
          </li>
        ))}
      </ul>
    </div>
  );
};

QaGroupBlock.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)).isRequired,
};

export default QaGroupBlock;

import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import BackgroundVideo from 'components/background-video/background-video';
import PoorText from 'components/poor-text/poor-text';
import imageResizer from 'js/image-resizer';
import useBreakpoints from 'hooks/use-breakpoints';

const PageHero = ({ heading, introHtml, image, imageMobile, video }) => {
  const { isMobile } = useBreakpoints(true);
  const [imageWidth, setImageWidth] = React.useState(0);

  React.useEffect(() => {
    setImageWidth(imageResizer.calcImageWidth(window.screen.width, 100));
  });

  const pageHeroImage = isMobile ? (imageMobile ? imageMobile : image) : image;

  return (
    <div className="page-hero">
      <div className="page-hero__media-container">
        {video ? (
          <div className="page-hero__video-container">
            <BackgroundVideo {...video} className="page-hero__video" />
          </div>
        ) : (
          pageHeroImage && (
            <Image
              {...pageHeroImage}
              fluid={!isMobile}
              width={isMobile ? null : imageWidth}
              className="page-hero__image image_size_page-hero"
            />
          )
        )}
      </div>

      <div className="page-hero__text-container">
        {(heading || introHtml) && (
          <div className="page-hero__text-content">
            {heading && <h1 className="page-hero__heading">{heading}</h1>}
            {introHtml && (
              <PoorText
                text={introHtml}
                className="page-hero__intro poor-text_in-page-hero"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

PageHero.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  imageMobile: PropTypes.exact(Image.propTypes),
  video: PropTypes.exact(BackgroundVideo.propTypes),
};

export default PageHero;
